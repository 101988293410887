import React from 'react'
import Helmet from 'react-helmet'

import Navbar from './Navbar'


import './style.css' // add this


const TemplateWrapper = ({ children }) => (
  <div className="layout">
    <Helmet title="Home | Gatsby + WordPress" />
    <header>Header</header>

    <Navbar />

    <main>{children}</main>

<h1>DSDadadasdasdsadadas</h1>
    <aside>Sidebar</aside>

    <footer>Footer</footer>
  </div>
)

export default TemplateWrapper
